<mat-toolbar class="mat-elevation-z5" color="primary">
    <div class="actions">
        <button *ngIf="actionsService.backLink" mat-icon-button routerLink="{{ actionsService.backLink }}">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <button *ngIf="!actionsService.backLink && actionsService.sidenav" mat-icon-button
            (click)="actionsService.sidenav.toggle()">
            <mat-icon>menu</mat-icon>
        </button>
    </div>
    <div class="options">
        <button class="navbutton" mat-icon-button [matMenuTriggerFor]="languageMenu" aria-label="Translation"
            matTooltip="{{ 'simple.language' | translate }}">
            <mat-icon>translate</mat-icon>
        </button>
    </div>
    <mat-progress-bar mode="indeterminate" [@loading]="loadingService.isLoading ? 'open' : 'closed'"></mat-progress-bar>
</mat-toolbar>

<mat-menu #languageMenu xPosition="before">
    <button class="flag" mat-menu-item (click)="selectLanguage('en')"
        [ngClass]="translateService.currentLang == 'en' ? 'active' : ''">
        <img src="assets/images/flags/en.svg" />English
    </button>
    <button class="flag" mat-menu-item (click)="selectLanguage('de')"
        [ngClass]="translateService.currentLang == 'de' ? 'active' : ''">
        <img src="assets/images/flags/de.svg" />Deutsch
    </button>
</mat-menu>