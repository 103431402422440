import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Wissensfloater } from 'backend-core-client';

@Component({
	selector: 'app-video',
	templateUrl: './video.component.html',
	styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnChanges {
	@Input() wissensfloater: Wissensfloater;

	id: string;

	clicked = false;

	constructor() {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.wissensfloater) {
			this.id = this.wissensfloater.url.replace('https://www.youtube.com/embed/', '');
		}
	}
}
