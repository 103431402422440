<h3>{{ 'simple.partner' | translate }}</h3>
<p>
    <a href="https://www.vdg.de" target="_blank">
        <img src="assets/partner/vdg.gif" />
    </a>
    <a href="https://www.stahl-online.de" target="_blank">
        <img src="assets/partner/vdeh.gif" />
    </a>
    <a href="https://www.umicore.de" target="_blank">
        <img src="assets/partner/umicore.png" />
    </a>
    <a href="https://www.thielenhaus.com" target="_blank">
        <img src="assets/partner/thielenhaus.png" />
    </a>
    <a href="https://www.mannesmann-linepipe.com" target="_blank">
        <img src="assets/partner/salzgitter.jpg" />
    </a>
    <a href="https://www.munu-kabel.de" target="_blank">
        <img src="assets/partner/muckenhaupt-nusselt-logo.png" />
    </a>
    <a href="https://www.mbau.uni-wuppertal.de" target="_blank">
        <img src="assets/partner/motor2.jpg" />
    </a>
    <a href="https://www.wf-wuppertal.de" target="_blank">
        <img src="assets/partner/wirwup.gif" />
    </a>
    <a href="https://www.lhoist.com" target="_blank">
        <img src="assets/partner/lhoist.jpg" />
    </a>
    <a href="https://www.sms-elotherm.com" target="_blank">
        <img src="assets/partner/elotherm.png" />
    </a>
    <a href="https://www.karldeutsch.de" target="_blank">
        <img src="assets/partner/deutsch.jpg" />
    </a>
    <a href="https://www.buderus-steel.com" target="_blank">
        <img src="assets/partner/buderus.png" />
    </a>
    <a href="https://www.vdi.de" target="_blank">
        <img src="assets/partner/vdi.png" />
    </a>
</p>