import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map } from 'rxjs';

@Pipe({
	name: 'translateObject',
})
export class TranslateObjectPipe implements PipeTransform {
	public constructor(private translateService: TranslateService) {}

	public transform(value: { [key: string]: string }): Observable<string> {
		return this.translateService.stream('none').pipe(map(() => value[this.translateService.currentLang]));
	}
}
