import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable({
	providedIn: 'root',
})
export class ActionsService {
	public backLink: string;
	public sidenav: MatSidenav;

	openSidenav() {
		if (this.sidenav != null && !this.sidenav.opened) {
			this.sidenav.open();
		}
	}

	closeSidenav() {
		if (this.sidenav != null && this.sidenav.opened) {
			this.sidenav.close();
		}
	}
}
