"use strict";
// tslint:disable
/**
 * Core
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.throwIfNullOrUndefined = exports.encodeURI = exports.COLLECTION_FORMATS = exports.RequiredError = exports.BaseAPI = exports.Configuration = exports.BASE_PATH = void 0;
const rxjs_1 = require("rxjs");
const ajax_1 = require("rxjs/ajax");
const operators_1 = require("rxjs/operators");
const servers_1 = require("./servers");
exports.BASE_PATH = servers_1.servers[0].getUrl();
class Configuration {
    constructor(configuration = {}) {
        this.configuration = configuration;
    }
    get basePath() {
        var _a;
        return (_a = this.configuration.basePath) !== null && _a !== void 0 ? _a : exports.BASE_PATH;
    }
    get middleware() {
        var _a;
        return (_a = this.configuration.middleware) !== null && _a !== void 0 ? _a : [];
    }
    get username() {
        return this.configuration.username;
    }
    get password() {
        return this.configuration.password;
    }
    get apiKey() {
        const { apiKey } = this.configuration;
        return apiKey ? (typeof apiKey === 'string' ? () => apiKey : apiKey) : undefined;
    }
    get accessToken() {
        const { accessToken } = this.configuration;
        return accessToken ? (typeof accessToken === 'string' ? () => accessToken : accessToken) : undefined;
    }
}
exports.Configuration = Configuration;
/**
 * This is the base class for all generated API classes.
 */
class BaseAPI {
    constructor(configuration = new Configuration()) {
        this.configuration = configuration;
        this.middleware = [];
        this.withMiddleware = (middlewares) => {
            const next = this.clone();
            next.middleware = next.middleware.concat(middlewares);
            return next;
        };
        this.withPreMiddleware = (preMiddlewares) => this.withMiddleware(preMiddlewares.map((pre) => ({ pre })));
        this.withPostMiddleware = (postMiddlewares) => this.withMiddleware(postMiddlewares.map((post) => ({ post })));
        this.createRequestArgs = ({ url: baseUrl, query, method, headers, body, responseType }) => {
            // only add the queryString to the URL if there are query parameters.
            // this is done to avoid urls ending with a '?' character which buggy webservers
            // do not handle correctly sometimes.
            const url = `${this.configuration.basePath}${baseUrl}${query && Object.keys(query).length ? `?${queryString(query)}` : ''}`;
            return {
                url,
                method,
                headers,
                body: body instanceof FormData ? body : JSON.stringify(body),
                responseType: responseType !== null && responseType !== void 0 ? responseType : 'json',
            };
        };
        this.rxjsRequest = (params) => (0, rxjs_1.of)(params).pipe((0, operators_1.map)((request) => {
            this.middleware.filter((item) => item.pre).forEach((mw) => (request = mw.pre(request)));
            return request;
        }), (0, operators_1.concatMap)((args) => (0, ajax_1.ajax)(args).pipe((0, operators_1.map)((response) => {
            this.middleware.filter((item) => item.post).forEach((mw) => (response = mw.post(response)));
            return response;
        }))));
        /**
         * Create a shallow clone of `this` by constructing a new instance
         * and then shallow cloning data members.
         */
        this.clone = () => Object.assign(Object.create(Object.getPrototypeOf(this)), this);
        this.middleware = configuration.middleware;
    }
    request(requestOpts, responseOpts) {
        return this.rxjsRequest(this.createRequestArgs(requestOpts)).pipe((0, operators_1.map)((res) => {
            const { status, response } = res;
            if (status >= 200 && status < 300) {
                return (responseOpts === null || responseOpts === void 0 ? void 0 : responseOpts.response) === 'raw' ? res : response;
            }
            throw res;
        }));
    }
}
exports.BaseAPI = BaseAPI;
/**
 * @deprecated
 * export for not being a breaking change
 */
class RequiredError extends Error {
    constructor() {
        super(...arguments);
        this.name = 'RequiredError';
    }
}
exports.RequiredError = RequiredError;
exports.COLLECTION_FORMATS = {
    csv: ',',
    ssv: ' ',
    tsv: '\t',
    pipes: '|',
};
const encodeURI = (value) => encodeURIComponent(`${value}`);
exports.encodeURI = encodeURI;
const queryString = (params) => Object.entries(params)
    .map(([key, value]) => value instanceof Array
    ? value.map((val) => `${(0, exports.encodeURI)(key)}=${(0, exports.encodeURI)(val)}`).join('&')
    : `${(0, exports.encodeURI)(key)}=${(0, exports.encodeURI)(value)}`)
    .join('&');
const throwIfNullOrUndefined = (value, paramName, nickname) => {
    if (value == null) {
        throw new Error(`Parameter "${paramName}" was null or undefined when calling "${nickname}".`);
    }
};
exports.throwIfNullOrUndefined = throwIfNullOrUndefined;
