import { Injectable } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

@Injectable({
  providedIn: "root"
})
export class LayoutService {
  private xsmall: boolean;
  private small: boolean;
  private medium: boolean;
  private large: boolean;
  private xlarge: boolean;

  private margin: number;

  constructor(breakpointObserver: BreakpointObserver) {
    breakpointObserver
      .observe(Breakpoints.XSmall)
      .subscribe(breakpoint => (this.xsmall = breakpoint.matches));
    breakpointObserver
      .observe(Breakpoints.Small)
      .subscribe(breakpoint => (this.small = breakpoint.matches));
    breakpointObserver
      .observe(Breakpoints.Medium)
      .subscribe(breakpoint => (this.medium = breakpoint.matches));
    breakpointObserver
      .observe(Breakpoints.Large)
      .subscribe(breakpoint => (this.large = breakpoint.matches));
    breakpointObserver
      .observe(Breakpoints.XLarge)
      .subscribe(breakpoint => (this.xlarge = breakpoint.matches));
    breakpointObserver
      .observe("(min-width: 720px)")
      .subscribe(breakpoint => (this.margin = breakpoint.matches ? 16 : 8));
  }

  isXSmall(): boolean {
    return this.xsmall;
  }

  isSmall(): boolean {
    return this.small;
  }

  isMedium(): boolean {
    return this.medium;
  }

  isLarge(): boolean {
    return this.large;
  }

  isXLarge(): boolean {
    return this.xlarge;
  }

  getMargin(): number {
    return this.margin;
  }
}
