"use strict";
// tslint:disable
/**
 * Core
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.WissensfloaterApi = void 0;
const runtime_1 = require("../runtime");
/**
 * no description
 */
class WissensfloaterApi extends runtime_1.BaseAPI {
    wissensfloaterControllerReadByCategory({ category }, opts) {
        (0, runtime_1.throwIfNullOrUndefined)(category, 'category', 'wissensfloaterControllerReadByCategory');
        return this.request({
            url: '/wissensfloater/{category}'.replace('{category}', (0, runtime_1.encodeURI)(category)),
            method: 'GET',
        }, opts === null || opts === void 0 ? void 0 : opts.responseOpts);
    }
    ;
}
exports.WissensfloaterApi = WissensfloaterApi;
