import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryApi } from 'backend-core-client';
import { ActionsService } from 'src/app/core/services/actions/actions.service';
import { LayoutService } from 'src/app/core/services/layout/layout.service';

@Component({
	selector: 'app-core',
	templateUrl: './core.component.html',
	styleUrls: ['./core.component.scss'],
})
export class CoreComponent implements OnInit, OnDestroy {
	@ViewChild('sidenav', { static: true })
	sidenav: MatSidenav;

	public categories$ = this.categoryApi.categoryControllerGetAllCategories();

	constructor(
		public layoutService: LayoutService,
		public actionsService: ActionsService,
		public activatedRoute: ActivatedRoute,
		public categoryApi: CategoryApi,
		public router: Router
	) {}

	async ngOnInit() {
		this.router.events.subscribe(() => {
			if (this.layoutService.isXSmall()) {
				this.sidenav.close();
			}
		});
		this.actionsService.sidenav = this.sidenav;

		const tag = document.createElement('script');
		tag.src = 'https://www.youtube.com/iframe_api';
		document.body.appendChild(tag);
	}

	ngOnDestroy(): void {
		this.actionsService.sidenav = undefined;
	}
}
