import { Injectable } from '@angular/core';
import { PartialObserver, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  readonly loading = new Subject<void>();

  get isLoading(): boolean {
    return this.loading.observers.length > 0;
  }

  subscribe(observer?: PartialObserver<void>) {
    return this.loading.subscribe(observer);
  }
}
