import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CoreComponent } from "./modules/core/core.component";

const routes: Routes = [
  {
    path: "core",
    component: CoreComponent,
    loadChildren: () =>
      import("./modules/core/core.module").then(m => m.CoreModule)
  },
  { path: "**", redirectTo: "core" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
