<mat-card *ngFor="let wissensfloater of wissensfloater$ | async">
	<app-video [wissensfloater]="wissensfloater"></app-video>
	<h4>{{
		wissensfloater.id === 0
			? (wissensfloater.name | translateObject | async)
			: wissensfloater.id + ' | ' + (wissensfloater.name | translateObject | async)
	}}</h4>
	<div class="buttons">
		<a
			*ngIf="wissensfloater.mp4"
			mat-icon-button
			[href]="'https://files.kominal.cloud/wissensfloater-production/mp4/' + wissensfloater.id + '.mp4'"
			matTooltip="{{ 'simple.downloadMP4' | translate }}"
			download
		>
			<mat-icon>movie</mat-icon>
		</a>
		<a
			*ngIf="wissensfloater.ppt"
			mat-icon-button
			[href]="'https://files.kominal.cloud/wissensfloater-production/ppt/' + wissensfloater.id + '.zip'"
			matTooltip="{{ 'simple.downloadPPT' | translate }}"
			download
		>
			<mat-icon>personal_video</mat-icon>
		</a>
	</div>
</mat-card>
