import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	constructor(private translate: TranslateService) {
		this.translate.setDefaultLang('de');
		let language = null;
		if (localStorage.getItem('language') != null) {
			language = localStorage.getItem('language');
		}
		if (language == null) {
			language = this.translate.getBrowserLang();
		}
		this.translate.use('de');
	}
}
