import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { WissensfloaterApi } from 'backend-core-client';
import { switchMap } from 'rxjs';

@Component({
	selector: 'app-wissensfloater',
	templateUrl: './wissensfloater.component.html',
	styleUrls: ['./wissensfloater.component.scss'],
})
export class WissensfloaterComponent {
	public wissensfloater$ = this.activatedRoute.params.pipe(
		switchMap(({ category }) => this.wissensfloaterApi.wissensfloaterControllerReadByCategory({ category }))
	);

	constructor(private activatedRoute: ActivatedRoute, public wissensfloaterApi: WissensfloaterApi, public sanitizer: DomSanitizer) {}
}
