import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { WissensfloaterComponent } from './pages/wissensfloater/wissensfloater.component';
import { CommentsComponent } from './pages/comments/comments.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ImprintComponent } from './pages/imprint/imprint.component';
import { AwardsComponent } from './pages/awards/awards.component';
import { SearchComponent } from './pages/search/search.component';
import { PartnerComponent } from './pages/partner/partner.component';

const routes: Routes = [
  { path: 'awards', component: AwardsComponent },
  { path: 'comments', component: CommentsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'home', component: HomeComponent },
  { path: 'imprint', component: ImprintComponent },
  { path: 'partner', component: PartnerComponent },
  { path: 'search', component: SearchComponent },
  { path: 'wissensfloater/:category', component: WissensfloaterComponent },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ],
})
export class CoreRoutingModule {}
