<mat-sidenav-container>
	<mat-sidenav
		#sidenav
		[mode]="layoutService.isXSmall() ? 'over' : 'side'"
		[fixedInViewport]="layoutService.isXSmall()"
		[opened]="!layoutService.isXSmall()"
	>
		<mat-nav-list>
			<mat-list-item routerLink="home" routerLinkActive="active">
				<mat-icon mat-list-icon>home</mat-icon>
				<span mat-line>{{ 'simple.home' | translate }}</span>
			</mat-list-item>
			<!--<mat-list-item routerLink="search" routerLinkActive="active">
                <mat-icon mat-list-icon>search</mat-icon>
                <span mat-line>{{ 'simple.search' | translate }}</span>
            </mat-list-item>-->
			<h5>{{ 'simple.wissensfloater' | translate }}</h5>
			<mat-list-item *ngFor="let category of categories$ | async" routerLink="wissensfloater/{{ category._id }}" routerLinkActive="active">
				<span mat-line>{{ category.name | translateObject | async }}</span>
			</mat-list-item>
			<h5>{{ 'simple.other' | translate }}</h5>
			<!--<mat-list-item routerLink="partner" routerLinkActive="active">
				<mat-icon mat-list-icon>group</mat-icon>
				<span mat-line>{{ 'simple.partner' | translate }}</span>
			</mat-list-item>
			<mat-list-item routerLink="awards" routerLinkActive="active">
				<mat-icon mat-list-icon>emoji_events</mat-icon>
				<span mat-line>{{ 'simple.awards' | translate }}</span>
			</mat-list-item>
			<mat-list-item routerLink="comments" routerLinkActive="active">
				<mat-icon mat-list-icon>comment</mat-icon>
				<span mat-line>{{ 'simple.comments' | translate }}</span>
			</mat-list-item>
			<mat-list-item routerLink="contact" routerLinkActive="active">
				<mat-icon mat-list-icon>call</mat-icon>
				<span mat-line>{{ 'simple.contact' | translate }}</span>
			</mat-list-item>-->
			<mat-list-item routerLink="imprint" routerLinkActive="active">
				<mat-icon mat-list-icon>business</mat-icon>
				<span mat-line>{{ 'simple.imprint' | translate }}</span>
			</mat-list-item>
		</mat-nav-list>
	</mat-sidenav>
	<mat-sidenav-content>
		<router-outlet></router-outlet>
	</mat-sidenav-content>
</mat-sidenav-container>
