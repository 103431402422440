import { Component } from '@angular/core';
import { LayoutService } from '../../services/layout/layout.service';
import { LoadingService } from '../../services/loading/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ActionsService } from '../../services/actions/actions.service';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss' ],
  animations: [
    trigger('loading', [
      state('open', style({ height: '4px' })),
      state('closed', style({ height: '0px' })),
      transition('open => closed', [ animate('250ms') ]),
      transition('closed => open', [ animate('250ms') ]),
    ]),
  ],
})
export class HeaderComponent {
  constructor(
    public actionsService: ActionsService,
    public layoutService: LayoutService,
    public loadingService: LoadingService,
    public translateService: TranslateService,
    public router: Router
  ) {}

  selectLanguage(language: string) {
    this.translateService.use(language);
    localStorage.setItem('language', language);
    window.location.reload();
  }
}
