<div class="logoDiv">
	<img class="logo" src="assets/images/logo/wissensfloater.png" />
</div>

<p>
	Wissens-Floater sind speziell für das Internet entwickelte Lehr- und Lernelemente mit hoher Qualität. Sie beschreiben anschaulich und
	einprägsam mit Fakten, Daten und Zahlen Themen aus Maschinenbau, Elektrotechnik, Informationstechnologie und Bautechnik. Das audiovisuelle
	Konzentrat aus Bildern, Animationen und Videos sowie lesbarem und gesprochenem Text ist ein kostenloses Lernerlebnis besonderer Art.
	Ersteller sind immer Experten aus Theorie und Praxis wie Firmen- und Konzernmitarbeiter, Lehrende an Hochschulen oder Fachleute mit
	entsprechenden Kenntnissen.
</p>

<div class="logoDiv">
	<img class="logoKnowledgeFloater" src="assets/images/logo/knowledgefloater.png" />
</div>

<p>
	Knowledge-Floater are high quality teaching and learning elements specially developed for the Internet. They describe vividly and
	memorable with facts, data and numbers topics from mechanical engineering, electrical engineering, information technology and construction
	engineering. The audiovisual concentrate of images, animations and videos as well as readable and spoken text is a special kind of free
	learning experience. The authors are always experts in theory and practice such as company and group employees, lecturers at universities
	or specialists with appropriate knowledge.
</p>

<h3>{{ 'simple.comments' | translate }}</h3>

<div class="comments">
	<img src="assets/comments/experts.jpg" />
	<img src="assets/comments/youtube_0001.jpg" />
	<img src="assets/comments/youtube_0002.jpg" />
	<img src="assets/comments/youtube_0003.jpg" />
</div>

<a href="assets/comments/experts.docx" target="_blank">Kommentare von Experten herunterladen</a><br />
<a href="assets/comments/youtube.docx" target="_blank">Kommentare von Nutzern herunterladen</a><br />

<h3>{{ 'simple.allWissensfloater' | translate }}</h3>

<div class="list">
	<img src="assets/wissensfloater/wissensfloater_0001.jpg" />
	<img src="assets/wissensfloater/wissensfloater_0002.jpg" />
	<img src="assets/wissensfloater/wissensfloater_0003.jpg" />
</div>

<a href="assets/comments/wissensfloater.docx" target="_blank">Liste von Wissensfloatern herunterladen</a><br />

<h3>{{ 'simple.awards' | translate }}</h3>

<div class="awards">
	<img src="assets/awards/awards1.png" />
	<img src="assets/awards/awards2.png" />
</div>

<h3>{{ 'simple.partner' | translate }}</h3>
<div class="partner">
	<a href="https://www.vdg.de" target="_blank">
		<img src="assets/partner/vdg.gif" />
	</a>
	<a href="https://www.stahl-online.de" target="_blank">
		<img src="assets/partner/vdeh.gif" />
	</a>
	<a href="https://www.umicore.de" target="_blank">
		<img src="assets/partner/umicore.png" />
	</a>
	<a href="https://www.thielenhaus.com" target="_blank">
		<img src="assets/partner/thielenhaus.png" />
	</a>
	<a href="https://www.mannesmann-linepipe.com" target="_blank">
		<img src="assets/partner/salzgitter.jpg" />
	</a>
	<a href="https://www.munu-kabel.de" target="_blank">
		<img src="assets/partner/muckenhaupt-nusselt-logo.png" />
	</a>
	<a href="https://www.mbau.uni-wuppertal.de" target="_blank">
		<img src="assets/partner/motor2.jpg" />
	</a>
	<a href="https://www.wf-wuppertal.de" target="_blank">
		<img src="assets/partner/wirwup.gif" />
	</a>
	<a href="https://www.lhoist.com" target="_blank">
		<img src="assets/partner/lhoist.jpg" />
	</a>
	<a href="https://www.sms-elotherm.com" target="_blank">
		<img src="assets/partner/elotherm.png" />
	</a>
	<a href="https://www.karldeutsch.de" target="_blank">
		<img src="assets/partner/deutsch.jpg" />
	</a>
	<a href="https://www.buderus-steel.com" target="_blank">
		<img src="assets/partner/buderus.png" />
	</a>
	<a href="https://www.vdi.de" target="_blank">
		<img src="assets/partner/vdi.png" />
	</a>
</div>
